<template>
  <div class="main">
    <b-tabs
      v-model="tabIndex"
      style="width: 100%"
    >
      <b-tab>
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("ProductsList") }}</strong>
        </template>
        <div style="display: flex;gap:10px;padding-top: 20px">
          <InternalMovingArticles @articleNumber="theArticleNumber" />
          <InternalMovingLocations
            :article-name="articleName"
          />
        </div>

      </b-tab>
      <b-tab @click="inPending">
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("Pending") }}</strong>
        </template>
        <div>
          <InternalArticlesPending @checkLocation="ifLocationExist" />
        </div>
      </b-tab>
      <b-tab @click="inStock">
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("Transferred") }}</strong>
        </template>
        <div>
          <InternalArticlesTransferred
            @locationValue="locationName"
            @dateValue="dateName"
          />
        </div>
      </b-tab>
      <b-tab @click="inStockReceived">
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("DeliveryDetails") }}</strong>
        </template>
        <div>
          <InternalArticlesReceived
            @locationValue="locationName"
            @dateValue="dateName"
          />
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import InternalMovingLocations from '@/components/productsInStock/InternalMovingLocations.vue'
import InternalArticlesPending from '@/components/productsInStock/InternalArticlesPending.vue'
import InternalArticlesTransferred from '@/components/productsInStock/InternalArticlesTransferred.vue'
import InternalMovingArticles from '@/components/productsInStock/InternalMovingArticles.vue'
import InternalArticlesReceived from '@/components/productsInStock/InternalArticlesReceived.vue'
import { mapActions } from 'vuex'
import moment from 'moment'

export default {
  components: {
    InternalMovingArticles,
    InternalMovingLocations,
    InternalArticlesPending,
    InternalArticlesTransferred,
    InternalArticlesReceived,
  },
  data() {
    return {
      tabIndex: null,
      articleName: null,
      location: null,
      receiverLocation: null,
      date: null,
    }
  },
  mounted() {
    this.movingLocations()
  },
  methods: {
    ...mapActions(['loadProductInStock', 'filterProductsStockOrderMovements', 'teamNames', 'movingLocations', 'filterProductsStockMovements', 'getTeamsPagination']),
    locationName(value) {
      this.receiverLocation = value
    },
    dateName(value) {
      this.date = value
    },
    ifLocationExist(value) {
      this.location = value
    },
    theArticleNumber(value) {
      this.articleName = value
    },
    inPending() {
      if (this.location != null) {
        this.filterProductsStockMovements({
          state: 'Pending',
          senderLocationId: this.location,
          pageNumber: 1,
          pageSize: 15,
        })
      }
      this.teamNames({
        pageNumber: 1,
        pageSize: 15,
      })
    },
    inStock() {
      this.filterProductsStockOrderMovements({
        shipType: 'ShipProductFromStoreToStore',
        movementState: 'InTransit',
        deliverDate: this.date ? moment(this.date, 'DD-MM-YYYY').format('YYYY-MM-DD') : null,
        receiverLocationId: this.receiverLocation ? this.receiverLocation : null,
        pageNumber: 1,
        pageSize: 15,
      })
      this.teamNames()
    },
    inStockReceived() {
      this.filterProductsStockOrderMovements({
        shipType: 'ShipProductFromStoreToStore',
        movementState: 'Received',
        deliverDate: this.date ? moment(this.date, 'DD-MM-YYYY').format('YYYY-MM-DD') : null,
        receiverLocationId: this.receiverLocation ? this.receiverLocation : null,
        pageNumber: 1,
        pageSize: 15,
      })
      this.teamNames()
    },
  },
}

</script>

<style>
.main {
  display: flex;
  gap: 10px;
}
</style>
