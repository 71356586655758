<template>
  <div>
    <b-sidebar
      id="change-received"
      ref="modal"
      v-model="modalShow"
      header-class="headerModalToggle"
      right
      shadow
      size="l"
      width="60%"
      :title="$t('OrdersHistory')"
      no-close-on-esc
      @close="onCancelToggle"
    >
      <template #title>
        <strong style="color: #101828;">{{ $t('OrdersHistory') }} : {{ orderMovementNumber.orderMovementNumber }}</strong>
      </template>
      <template
        v-slot:header-close
      >
        <button
          style="color: #98A2B3;padding-left: 0px; padding-right: 0px;height: 60px;"
          class="fa fa-close"
          @click="onCancelToggle"
        />
      </template>
      <div style="padding:20px;">
        <table
          v-if="getProductsInStockMovementId.length > 0"
          class="team_table"
        >
          <thead>
            <tr>
              <th style="display: flex;gap: 5px">
                <input
                  type="checkbox"
                  :checked="selectAll"
                  :disabled="allItemsReceived"
                  @change="toggleSelectAll"
                >
                {{ $t('Select') }}
              </th>
              <th>
                {{ $t('ArticleNumber') }}
              </th>
              <th>
                {{ $t('State') }}
              </th>
              <th>
                {{ $t('Image') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item,index) in getProductsInStockMovementId"
              :key="index"
            >
              <td>
                <input
                  type="checkbox"
                  :value="item.productInStockId"
                  :checked="isItemChecked(item.productInStockId)"
                  :disabled="item.currentState === 'Received'"
                  @change="event => toggleOrderItem(event, index)"
                >
              </td>
              <td>
                {{ item.articleNumber }}
              </td>
              <td>
                {{ $t(item.currentState) }}
              </td>
              <td v-if="item.photo">
                <img
                  v-b-tooltip="$t('ClickToViewPhoto')"
                  style="height: 40px; border-radius: 3px;cursor:pointer; "
                  :src="item.photo"
                  @click.stop="getAllImages(item)"
                >
              </td>
              <td v-else>
                <img
                  v-b-tooltip="$t('NoImageOrDocument')"
                  style="width:30px;height:30px"
                  src="../../../assets/images/noImageAvailable.svg"
                >
              </td>
            </tr>
          </tbody>
        </table>
        <div
          v-else
          style="padding: 15px"
        >
          <p style="font-weight: 600">
            {{ $t('NoArticlesFound') }}
          </p>
        </div>
      </div>
      <div
        v-if="getProductsInStockMovementId.length > 0"
        class="buttonsEverywhere"
        style="gap: 10px;margin: 0% !important;padding: 10px;"
      >
        <b-button
          type="button"
          variant="none"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t('Save') }}
        </b-button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
      <vue-easy-lightbox
        :esc-disabled="false"
        :visible="image.visible"
        :imgs="image.imgs"
        :index="image.index"
        @hide="handleHide"
      />
    </b-sidebar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VueEasyLightbox from 'vue-easy-lightbox'




export default {
  components: {
    VueEasyLightbox,
  },
  props: ['orderMovementNumber'],
  data() {
    return {
      modalShow: false,
      image: {
        zoom: 1,
        imgs: [],
        visible: false,
        index: 0,
      },
      selectAll: false,
      productInStockIds: [],
    };
  },
  computed: {
    ...mapGetters(['getProductsInStockMovementId']),
    allItemsReceived() {
      return this.getProductsInStockMovementId.every((item) => { return item.currentState === 'Received' })
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions([]),
    async onSubmit() {
      const objekti = {
        productInStockIds: this.productInStockIds,
        orderMovementId: this.orderMovementNumber.orderMovemetId,
      }
      this.$emit('onCancelToggle');
      if (this.productInStockIds.length > 0) {
        this.$emit('submitForm', objekti);
      }
      this.$refs.modal.hide();
      this.onReset();
    },
    onCancel() {
      this.$emit('onCancelToggle');
      this.$refs.modal.hide();
      this.onReset();
    },
    onReset() {
      this.selectAll = false
      this.productInStockIds = []
    },
    onCancelToggle() {
      this.onReset();
      this.$emit('onCancelToggle');
    },
    async getAllImages(item) {
      this.image.imgs = [];
      const filteredImages = this.getProductsInStockMovementId.filter((image) => {
        return image.articleNumber === item.articleNumber;
      });
      for (let i = 0; i < filteredImages.length; i++) {
        const image = filteredImages[i];
        try {
          this.image.imgs.push({
            src: image.photo,
          });
        } catch (e) {
          console.log(e);
        }
      }
      this.show();
    },
    show() {
      this.image.visible = true
    },
    handleHide() {
      this.image.visible = false
      this.image.imgs.length = []
    },
    toggleOrderItem(event, index) {
      const item = this.getProductsInStockMovementId[index];
      const itemObject = item.productInStockId

      if (event.target.checked) {
        this.productInStockIds.push(itemObject);
      } else {
        const itemIndex = this.productInStockIds.findIndex(
          (i) => { return i === item.productInStockId },
        );
        if (itemIndex !== -1) {
          this.productInStockIds.splice(itemIndex, 1);
        }
      }
      this.selectAll = this.productInStockIds.length === this.getProductsInStockMovementId.filter((itemz) => { return itemz.currentState !== 'Received' }).length;
    },
    toggleSelectAll(event) {
      this.selectAll = event.target.checked;

      if (this.selectAll) {
        this.productInStockIds = this.getProductsInStockMovementId
          .filter((item) => { return item.currentState !== 'Received' })
          .map((item) => { return item.productInStockId });
      } else {
        this.productInStockIds = [];
      }
    },
    isItemChecked(item) {
      return this.productInStockIds.some(
        (i) => { return i === item },
      );
    },
  },
};
</script>

<style scoped>
.team_table td {
  padding: 16px 9px !important;
}
.hover-row:hover {
  background-color: rgba(255, 39, 79, 0.1);
}
</style>
