<template>
  <div>
    <b-sidebar
      id="historic-received"
      ref="modal"
      v-model="modalShow"
      header-class="headerModalToggle"
      right
      shadow
      size="l"
      width="60%"
      :title="$t('OrdersHistory')"
      no-close-on-esc
      @close="onCancelToggle"
    >
      <template #title>
        <strong style="color: #101828;">{{ $t('OrdersHistory') }} : {{ orderMovementNumber.orderMovementNumber }}</strong>
      </template>
      <template
        v-slot:header-close
      >
        <button
          style="color: #98A2B3;padding-left: 0px; padding-right: 0px;height: 60px;"
          class="fa fa-close"
          @click="onCancelToggle"
        />
      </template>
      <div
        v-for="(item,index) in groupedProducts"
        :key="index"
        style="padding:20px;"
      >
        <p><strong>{{ $t('ArticleNumber') }} : {{ item.articleNumber }}</strong></p>
        <table
          class="team_table"
        >
          <thead>
            <tr>
              <th>
                {{ $t('ExecutorName') }}
              </th>
              <th>
                {{ $t('State') }}
              </th>
              <th>
                {{ $t('Created') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(itemz,indexz) in item.details"
              :key="indexz"
            >
              <td>
                {{ itemz.executorFullName }}
              </td>
              <td>
                <p :class="stateClass(itemz.state)">
                  {{ $t(itemz.state) }}
                </p>
              </td>
              <td>
                {{ formatDate(itemz.created) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-if="Object.keys(groupedProducts).length === 0"
        style="padding: 20px"
      >
        <p style="font-weight: 600">
          {{ $t('NoArticlesFound') }}
        </p>
      </div>
      <vue-easy-lightbox
        :esc-disabled="false"
        :visible="image.visible"
        :imgs="image.imgs"
        :index="image.index"
        @hide="handleHide"
      />
    </b-sidebar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VueEasyLightbox from 'vue-easy-lightbox'
import moment from 'moment'



export default {
  components: {
    VueEasyLightbox,
  },
  props: ['orderMovementNumber'],
  data() {
    return {
      modalShow: false,
      image: {
        zoom: 1,
        imgs: [],
        visible: false,
        index: 0,
      },
    };
  },
  computed: {
    ...mapGetters(['getProductsInStockByMovementId']),
    groupedProducts() {
      const stateOrder = {
        Received: 1,
        InTransit: 2,
        Pending: 3,
      };

      return this.getProductsInStockByMovementId.reduce((acc, product) => {
        const {
          articleNumber, created, state, executorId, executorFullName, productInStockId,
        } = product;

        if (!acc[articleNumber]) {
          acc[articleNumber] = {
            articleNumber,
            details: [],
          };
        }

        acc[articleNumber].details.push({
          created, state, executorId, executorFullName, productInStockId,
        });

        acc[articleNumber].details.sort((a, b) => { return stateOrder[a.state] - stateOrder[b.state] });

        return acc;
      }, {});
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions([]),
    formatDate(value) {
      return moment(value).format('YYYY-MM-DD, HH:mm:ss')
    },
    stateClass(value) {
      return {
        green: value === 'Received',
        red: value === 'Pending',
        orange: value === 'InTransit',
      }
    },
    onCancelToggle() {
      this.$emit('onCancelToggle');
    },
    async getAllImages(item) {
      // Set the array to an empty state at the beginning
      this.image.imgs = [];

      // Filter images based on the materialVariantId
      const filteredImages = this.getProductsInStockByMovementId.filter((image) => {
        return image.articleNumber === item.articleNumber;
      });
      // Fetch and push the filtered images
      for (let i = 0; i < filteredImages.length; i++) {
        const image = filteredImages[i];
        try {
          // Access the single photo in the 'image.photos' property and push it to 'this.image.imgs'
          this.image.imgs.push({
            src: image.photo, // Assign the fetched image
          });
        } catch (e) {
          console.log(e);
        }
      }

      // Continue with your other logic
      this.show();
    },
    show() {
      this.image.visible = true
    },
    handleHide() {
      this.image.visible = false
      this.image.imgs.length = []
    },
  },
};
</script>

<style scoped>
.team_table td {
  padding: 16px 9px !important;
}
.hover-row:hover {
  background-color: rgba(255, 39, 79, 0.1);
}
.green {
  color: green;
}

.red {
  color: red;
}

.orange {
  color: orange;
}
</style>
