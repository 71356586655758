<template>
  <div class="location">
    <div style="display:flex;justify-content: flex-end;margin-bottom: 10px;">
      <button
        v-b-toggle.location-articles
        class="button"
        @click="openSidebar"
      >
        {{ $t('AddToOrder') }}
      </button>
    </div>
    <div style="border: 1px solid lightgray;">
      <div style="height: 40vh; overflow-y: scroll; border-bottom: 1px solid lightgray">
        <div style="width: 100%;">
          <table
            class="team_table"
            style="width: 100%; box-shadow: none; font-size: 13px;cursor:auto"
          >
            <thead>
              <tr>
                <th style="display: flex;gap: 5px">
                  <input
                    type="checkbox"
                    :checked="selectAll"
                    @change="toggleSelectAll"
                  >
                  {{ $t('Select') }}
                </th>
                <th>
                  {{ $t('Location') }}
                </th>
                <th>
                  {{ $t('Amount') }}
                </th>
              </tr>
            </thead>
            <tbody v-if="getTotalStockGroupByLocation.length > 0 && articleName">
              <tr
                v-for="(article, index) in getTotalStockGroupByLocation"
                :key="index"
              >
                <td>
                  <input
                    type="checkbox"
                    :value="article.locationId"
                    :checked="isItemChecked(article)"
                    @change="event => toggleOrderItem(event, index)"
                  >
                </td>
                <td>{{ $t(article.locationName) }}</td>
                <td>{{ $t(article.totalAmountInStock) }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td
                  colspan="5"
                >
                  {{ $t('NoLocationFound') }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div style="height: 40vh; overflow-y: scroll; margin-top: 10px">
        <button
          v-if="articleName"
          style="margin: 1% 0%; width: 25%"
          class="button"
          @click="showArticlesInPending"
        >
          {{ $t('ShowArticlesInPending') }}
        </button>
        <span
          v-if="showInPending"
          style="margin-left: 10px;"
        >{{ $t('PendingTransferredArticles') }}</span>
        <table
          v-if="showInPending && articleName"
          class="team_table"
          style="width: 100%; box-shadow: none; font-size: 13px;cursor: auto"
        >
          <thead>
            <tr>
              <th>
                {{ $t('State') }}
              </th>
              <th>
                {{ $t('Location') }}
              </th>
              <th>
                {{ $t('ReceiverLocation') }}
              </th>
            </tr>
          </thead>
          <tbody v-if="getItemsInPendingOrTransit.length > 0">
            <tr
              v-for="(items, index) in getItemsInPendingOrTransit"
              :key="index"
            >
              <td><p :class="stateClass(items.currentState)">
                {{ $t(items.currentState) }}
              </p></td>
              <td>{{ items.senderLocationName }}</td>
              <td>{{ items.receiverLocationName }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="2">
                {{ $t('NoArticle') }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <ConfirmationToggle
      :location-place="locationPlace"
      @confirmationLocations="updateList"
      @onCancelToggle="onCancelToggle"
    />
    <div
      v-if="isSidebarOpen"
      class="backdrop"
    />
  </div></template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import ConfirmationToggle from './modals/ConfirmationToggle.vue'

export default {
  components: {
    ConfirmationToggle,
  },
  props: ['articleName'],
  data() {
    return {
      isSidebarOpen: false,
      location: null,
      locationId: null,
      stateOfRecycle: null,
      productInStockIdRecycle: null,
      pageSize: 15,
      currentLocation: null,
      locationPlace: [],
      selectAll: false,
      showInPending: false,
    }
  },
  computed: {
    ...mapGetters(['getItemsInPendingOrTransit', 'getLocationsMoving', 'getTotalStockGroupByLocation', 'getTotalStockGroupByLocation']),
  },
  watch: {
    getTotalStockGroupByLocation(newVal) {
      const currentArticleLocations = newVal.map((item) => {
        return {
          locationName: item.locationName,
          articleName: this.articleName,
          locationId: item.locationId,
        }
      });

      const selectedCurrentArticleLocations = this.locationPlace.filter(
        (item) => { return item.articleName === this.articleName },
      );

      this.selectAll = selectedCurrentArticleLocations.length === currentArticleLocations.length;
    },
    articleName(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.showInPending = false;
      }
    },
  },
  mounted() {
    this.$on('resetShowPending', this.resetShowPending);
  },

  methods: {
    ...mapActions(['getPendingOrTransitItems', 'totalStockGroupedByLocation', 'addProductInStockMovement', 'teamNames', 'productInStock_location_Pagination', 'updateRecycleStock', 'movingLocations']),
    openSidebar() {
      this.isSidebarOpen = true
    },
    stateClass(value) {
      return {
        green: value === 'Received',
        red: value === 'Pending',
        orange: value === 'InTransit',
      }
    },
    showArticlesInPending() {
      this.showInPending = true;
      this.getPendingOrTransitItems({ articleNumber: this.articleName })
    },
    resetShowPending() {
      this.showInPending = false;
    },
    toggleOrderItem(event, index) {
      const item = this.getTotalStockGroupByLocation[index];
      const itemObject = {
        locationName: item.locationName,
        articleName: this.articleName,
        locationId: item.locationId,
      };

      if (event.target.checked) {
        this.locationPlace.push(itemObject);
      } else {
        const itemIndex = this.locationPlace.findIndex(
          (i) => { return i.locationName === item.locationName && i.locationId === item.locationId && i.articleName === this.articleName },
        );
        if (itemIndex !== -1) {
          this.locationPlace.splice(itemIndex, 1);
        }
      }
      this.selectAll = this.locationPlace.filter(
        (itemz) => { return itemz.articleName === this.articleName },
      ).length === this.getTotalStockGroupByLocation.length;
    },
    toggleSelectAll(event) {
      this.selectAll = event.target.checked;

      if (this.selectAll) {
        this.locationPlace = [
          ...this.locationPlace.filter((item) => { return item.articleName !== this.articleName }),
          ...this.getTotalStockGroupByLocation.map((item) => {
            return {
              locationName: item.locationName,
              articleName: this.articleName,
              locationId: item.locationId,
            }
          }),
        ];
      } else {
        this.locationPlace = this.locationPlace.filter(
          (item) => { return item.articleName !== this.articleName },
        );
      }
    },
    isItemChecked(item) {
      return this.locationPlace.some(
        (i) => { return i.locationName === item.locationName && i.locationId === item.locationId && i.articleName === this.articleName },
      );
    },
    dateModified(xdate) {
      return moment(xdate).format('DD/MM/YYYY - HH:mm');
    },
    orderStateLocation(value) {
      this.currentLocation = value
      this.movingLocations()
      this.teamNames()
    },
    changeStateToStock(obj) {
      this.updateRecycleStock({
        object: obj,
        successCallback: () => {
          this.totalStockGroupedByLocation(
            {
              articleNumber: this.articleName,
            },
          )
        },
      })
    },
    onCancelToggle() {
      this.isSidebarOpen = false
    },
    updateList(obj) {
      this.addProductInStockMovement({
        object: obj[0],
        successCallback: () => {
          this.totalStockGroupedByLocation(
            {
              articleNumber: this.articleName,
            },
          )

          this.selectAll = false
          this.locationPlace = []
          this.isSidebarOpen = false
        },
      })
      this.selectAll = false
      this.locationPlace = []
      this.isSidebarOpen = false
    },
  },
}
</script>

<style scoped>
.team_table td {
  padding: 10px 9px !important;
}
.location {
  width: 50%;
}
.green {
  color: green;
}

.red {
  color: red;
}

.orange {
  color: orange;
}
@media screen and (max-width: 1200px) {
  .location {
    width: 100%;
  }
}
</style>
