<template>
  <div>
    <b-sidebar
      id="location-articles"
      ref="modal"
      v-model="modalShow"
      header-class="headerModalToggle"
      right
      shadow
      size="l"
      width="50%"
      :title="$t('ConfirmationToggle')"
      no-close-on-esc
      @close="onCancelToggle"
    >
      <template #title>
        <strong style="color: #101828;">{{ $t('ConfirmationToggle') }}</strong>
      </template>
      <template
        v-slot:header-close
      >
        <button
          style="color: #98A2B3;padding-left: 0px; padding-right: 0px;height: 60px;"
          class="fa fa-close"
          @click="onCancelToggle"
        />
      </template>
      <div style="padding:20px;">
        <div style="width: 100%;">
          <table
            class="team_table"
            style="width: 100%; box-shadow: none; font-size: 13px;cursor:auto"
          >
            <thead>
              <tr>
                <th>
                  {{ $t('ArticleName') }}
                </th>
                <th>
                  {{ $t('Location') }}
                </th>
                <th>
                  {{ $t('Remove') }}
                </th>
              </tr>
            </thead>
            <tbody v-if="locationPlace.length > 0">
              <tr
                v-for="(article, index) in locationPlace"
                :key="index"
              >
                <td>{{ $t(article.articleName) }}</td>
                <td>{{ $t(article.locationName) }}</td>
                <td>
                  <b-icon
                    icon="trash-fill"
                    style="font-size: 25px;cursor:pointer"
                    @click="removeLocation(index)"
                  />
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td
                  colspan="5"
                >
                  {{ $t('NoLocations') }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        v-if="locationPlace.length > 0"
        class="buttonsEverywhereToggle"
        style="gap: 20px"
      >
        <b-button
          type="button"
          variant="none"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t('Save') }}
        </b-button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';




export default {
  components: {
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['locationPlace'],
  data() {
    return {
      modalShow: false,
    };
  },
  computed: {
    ...mapGetters([]),
  },
  mounted() {
  },
  methods: {
    ...mapActions([]),
    removeLocation(index) {
      this.locationPlace.splice(index, 1);
    },
    async onSubmit() {
      const list = this.locationPlace.map((item) => {
        return {
          locationId: item.locationId,
          articleNumber: item.articleName,
        }
      })
      this.$emit('onCancelToggle');
      this.$emit('confirmationLocations', list);
      this.$refs.modal.hide();
    },
    onCancel() {
      this.$emit('onCancelToggle');
      this.$refs.modal.hide();
    },
    onCancelToggle() {
      this.$emit('onCancelToggle');
    },
  },
};
</script>

<style scoped>
.team_table td {
  padding: 10px 9px !important;
}
</style>
